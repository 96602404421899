import React, {useState} from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import Container from "@mui/material/Container";
import Visual from "../components/Visual";
import FormAgenzie from "../components/FormAgenzie";
import FormDirezione from "../components/FormDirezione";
import {Backdrop, CircularProgress, Snackbar, Typography} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import {SuccessDialog} from "../components/SuccessDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Home = () => {
    const [ospite, setOspite] = useState('')
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState('')
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')

    const handleSetSuccess = (bool,message) => {
        console.log(bool, message)
        setSuccess(bool)
        setSuccessMessage(message)
    }

    const handleSetOpen = () => {
        setOpen(true)
    }

    const handleSetSending = (bool) => {
        setSending(bool)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSetMessage = (content) => {
        setMessage(content)
    }

    const handleSetOspite = (event, ospite) => {
        setOspite(ospite)

        setTimeout(() => {
            const anchor = (event.target.ownerDocument || document).querySelector(
                '#form',
            );
            console.log(anchor)
            if (anchor) {
                anchor.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }, 100)
    }

    return (
        <DefaultLayout>
            <Visual handleSetOspite={handleSetOspite}/>
            <Container maxWidth={'md'} sx={{py:2}}>
                <Typography>
                    Gentile Ospite,
                    <br/>
                    il 17 giugno si è conclusa la fase di iscrizione all’evento di Vinci con Reale.
                    <br/>
                    Ti aspettiamo quindi a <b>Milano</b> <b style={{color:'#004b9b'}}>mercoledì 13 luglio alle ore 18,00</b> <b>in Piazza Gae Aulenti</b> per il welcome cocktail <b>al&nbsp;Pandenus (<a href={'https://www.pandenus.it/it/'} target={'_blank'}>https://www.pandenus.it/it/</a>)</b>.
                    <br/>
                    La serata proseguirà agli <b>IBM STUDIOS</b> e si concluderà intorno alle ore 22.30.
                    <br/>
                    <br/>
                    Per qualsiasi necessità puoi contattare la segreteria organizzativa:
                    <br/>
                    <b>Segreteria Organizzativa</b>
                    <br/>
                    Tel.: 02.97.37.88.34 (Lun-Ven h 9,00 - 13,00 / 14,00 - 18,00)
                    <br/>
                    Email: <a href={'mailto:segreteria@vcr-realemutua.it'} target={'_blank'}>segreteria@vcr-realemutua.it</a>
                </Typography>
            </Container>
        </DefaultLayout>
    )
}

export default Home
