import Container from "@mui/material/Container";
import DefaultLayout from "../layouts/DefaultLayout";
import {Typography} from "@mui/material";
import SezioneAnagrafica from "../components/SezioneAnagrafica";
import Box from "@mui/material/Box";

const Info = () => {
    return (
        <DefaultLayout>
            <Container>
                <Box sx={{pt: 3, pb: 1, mb: 2}} style={{borderBottom: '3px solid #e1ad35'}}>
                    <Typography variant={'h4'}>Info & Contatti</Typography>
                </Box>
                <Typography variant={'body1'}>
                    Puoi contattare la <b>Segreteria Organizzativa</b> dal Lunedì al Venerdì, con orari 9,00 - 13,00 / 14,00 - 18,00<br/>
                    Telefonicamente, al numero 02.97.37.88.34<br/>
                    Oppure tramite email all'indirizzo <a href={"mailto:segreteria@vcr-realemutua.it"}>segreteria@vcr-realemutua.it</a><br/>
                </Typography>
            </Container>

        </DefaultLayout>
    )
}

export default Info;
