import logo from './logo.svg';
import './App.css';
import {Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {realeMutua} from "./theme/realeMutua";
import Info from "./pages/Info";

function App() {
  return (
      <ThemeProvider theme={realeMutua}>
          <CssBaseline/>
          <Routes>
              <Route path={'/'} element={<Home/>}/>
              <Route path={'/info'} element={<Info/>}/>
          </Routes>
      </ThemeProvider>

  );
}

export default App;
