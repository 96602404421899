import {createTheme} from "@mui/material";

export const realeMutua = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides:{
                root:{
                    boxShadow:'none',
                    background:"white"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root:{
                    borderRadius:'0'
                }
            }
        }
    },

    palette: {
        primary:{
            main:'#e1ad35',
            contrastText:'#041682'
        }
    }
})
