import React from "react";
import Box from "@mui/material/Box";
import Header from "../components/Header";

const DefaultLayout = ({children}) => {
    return (
        <Box>
            <Header/>
            <Box>{children}</Box>
        </Box>
    )
}

export default DefaultLayout
