import React from "react";
import visual from '../assets/images/bkg.png'
import logo from '../assets/images/vinci-reale.png'
import data from '../assets/images/data.png'
import dataMobile from '../assets/images/data_mobile.png'
import header from '../assets/images/header.jpg'
import headerMobile from '../assets/images/header-mobile.jpg'
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";

const Visual = ({handleSetOspite}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return(
        <img draggable={"false"} alt={'visual'} width={'100%'} src={matches ? header : headerMobile} className={'visual'}/>
  )
}

export default Visual
